<section>
  <div class="circle"></div>
  <header>
    <a href="#" class="logo"><img src="/assets/images/Frame%202136137739.png" alt="no photo"></a>
    <ul>
      <li>info@app-insof-nasiya.uz</li>
      <li>+998 90 061 35 70</li>

    </ul>
  </header>
  <div class="content">
    <div class="textBox">
      <h2> Insof &nbsp;<span>Nasiya</span></h2>
      <br>
      <p>Рассрочка — это финансовый инструмент,
        который позволяет приобретать товары или услуги по оплате частей в течение определенного периода времени.
        Вместо того чтобы сразу оплатить полную стоимость, покупатель вносит первоначальный взнос и
        затем выплачивает оставшуюся полученную сумму равными частями в течение нескольких месяцев или лет.
      </p>
    </div>
    <div class="imgBox">
      <img src="/assets/images/image%206.png" alt="no phone">
    </div>
  </div>


</section>
<div class="footer">
  <div class="left">
    <ul>
      <li>
        <a href="https://docs.google.com/document/d/1xMvvSAmhorhY8E632LtwM0qHdrVZ3LHnvOREdHKY_rU/edit?usp=sharing" target="_blank">
          Terms
        </a>
      </li>
      <li>
        <a href="https://docs.google.com/document/d/1QGB2kxRnPr2mJqXyMU3IZJYOz3rHfKpFEv1RPusi-LU/edit?usp=sharing" target="_blank">
          Privacy Police
        </a>
      </li>
    </ul>
  </div>
  <div class="right">
    <span>Copyright © INSOF NASIYA 2024</span>
  </div>
</div>
